import { Injectable } from '@angular/core';
import {
  PostRegisterValidateApiBody,
  PostRegisterSubmitApiBody,
} from '../../../shared/interfaces/api/auth-api.interface';
import { AccountApiService } from '../../api/account-api.service';
import { AuthApiService } from '../../api/auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private registerData = {
    userToken: '',
    email: '',
    emailOptout: false,
    password: '',
    voucher: '',
    emailRequired: false,
  };

  constructor(
    private accountApiService: AccountApiService,
    private authApiService: AuthApiService
  ) {}

  public getRegisterData() {
    return this.registerData;
  }

  public registerValidate(data: PostRegisterValidateApiBody) {
    return this.authApiService.postValidateRegister(data);
  }

  public registerSubmit(data: PostRegisterSubmitApiBody) {
    return this.authApiService.postSubmitRegister(data);
  }

  public postLogout() {
    return this.accountApiService.postLogoutCurrentSession();
  }
}
